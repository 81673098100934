import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { GlobalStyles } from './config/GlobalStyles';
import { GlobalFonts } from '@config/GlobalFonts';
import { routes } from '@config/routes';
import Winamp from '@pages/Winamp';
import PresaveMorokomor from '@pages/PresaveMorokomor';
import Monada from '@pages/Monada';


import * as Styled from './styled';

declare global {
	interface Window {
		_tmr:any;
	}
}

const App = () => {
	return (
		<Styled.App>
			<GlobalStyles />
			<GlobalFonts />
			<Routes>
				<Route path={routes.home} element={<PresaveMorokomor />} />
				<Route path={routes.monada} element={<Monada />} />
				<Route path={routes.winamp} element={<Winamp />} />
			</Routes>
		</Styled.App>
	);
}

export default App;
