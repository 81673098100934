import styled from "styled-components";
import { screens } from "@config/screens";

export const WinampWrapper = styled.div`
	/* display: flex;
	align-items: center !important;
	justify-content: center !important;
	width: 100vw;
	height: 100vh; */
`;

export const Winamp = styled.div`

`;



