import React, { FC, FormEvent, useEffect, useState } from 'react';
import axios from 'axios';
import { IMonada } from './types';
import { content } from './scheme';
import { useLocation } from 'react-router-dom';

import * as Styled from './styled';


export const Monada: FC<IMonada> = ({}) => {
	return (
		<>
			(
				<Styled.PresaveMorokomorBox bgImage={content.bgImage}>
					<Styled.PresaveMorokomor>
						<Styled.Cover src={content.cover} />
						<Styled.TextBox>
							<Styled.Artist isArtist={true}>{content.artist}</Styled.Artist>
							<Styled.Song>{content.song}</Styled.Song>
						</Styled.TextBox>
						<Styled.StreamItems>
							{content.streamings.map((streaming, index) => (
								<Styled.StreamItem key={`Item_${index}`}>
									<Styled.StreamItemIcon src={streaming.logo} />
									<Styled.StreamItemName>{streaming.name}</Styled.StreamItemName>
									<Styled.StreamItemLink onClick={() => window._tmr.push({ type: 'reachGoal', id: 3486951, goal: 'stream_button_click'})} href={streaming.button.link}>{streaming.button.text}</Styled.StreamItemLink>
								</Styled.StreamItem>
							))}
						</Styled.StreamItems>
					</Styled.PresaveMorokomor>
				</Styled.PresaveMorokomorBox>
			)
		</>
	);
}