import React, { FC, useEffect, useRef, useState, useLayoutEffect } from 'react';
import Webamp from 'webamp';
import { IWinamp } from './types';
import { initialTracks } from './scheme';

import * as Styled from './styled';


export const Winamp: FC<IWinamp> = ({}) => {
	const webampRef = useRef<HTMLDivElement>(null);
	const [isRender, setIsRender] = useState(false);

    useLayoutEffect(() => {
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    const addedNode = mutation.addedNodes[0] as HTMLElement;
                    if (addedNode.id === 'webamp') {
						const winamp = addedNode.children[0] as HTMLElement;
						if (winamp) {
							winamp.style.display = 'none';
						}
                    }
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, []);

	useEffect(() => {
		if (webampRef.current) {
			const webamp = new Webamp({initialTracks});
			setTimeout(() => {
				setIsRender(true);

			}, 1)
		  	webamp.renderWhenReady(webampRef.current);
		}
	}, []);




	const handleEqCloseClick = (event: Event) => {
		event.preventDefault();
		event.stopPropagation();
	};


	useEffect(() => {
		if (isRender) {
			const element = document.querySelector<HTMLElement>('#webamp');

			const wrapper = document.createElement('div');
			wrapper.id = 'winamp';
			wrapper.style.width = '100vw';
			wrapper.style.height = '100vh';
			wrapper.style.display = 'flex';
			wrapper.style.alignItems = 'center';
			wrapper.style.justifyContent = 'center';
			wrapper.style.backgroundImage = 'url(/images/Winamp/4.webp)';
			wrapper.style.backgroundRepeat = 'no-repeat';
			wrapper.style.backgroundSize = 'cover';
			wrapper.style.backgroundPosition = 'center';


			if (element) {
				element.parentNode?.insertBefore(wrapper, element)
  				wrapper.appendChild(element);
			};



			const closeButton = document.querySelector('#close');
			const titleBar = document.querySelector<HTMLDivElement>('#title-bar');
			const eqBar = document.querySelector<HTMLDivElement>('.equalizer-top.title-bar.draggable');
			const webamp = document.querySelector<HTMLDivElement>('#webamp');
			const mainWindow = document.querySelector<HTMLDivElement>('#main-window');
			const playlistWindow = document.querySelector<HTMLDivElement>('#playlist-window');
			const eqWindow = document.querySelector<HTMLDivElement>('#equalizer-window');
			const eqWindowSelected = document.querySelector<HTMLDivElement>('#equalizer-window.selected');
			const about = document.querySelector<HTMLDivElement>('#about');
			const option = document.querySelector<HTMLDivElement>('#option');
			const playlistListMenu = document.querySelector<HTMLDivElement>('#playlist-list-menu');
			const playlistHeader = document.querySelector<HTMLDivElement>('.playlist-top.draggable');
			const addUrl = document.querySelector<HTMLDivElement>('#playlist-add-menu');
			const eqButton = document.querySelector('#equalizer-button.selected');
			const playlistButton = document.querySelector('#playlist-button.selected');
			const playlistCloseButton = document.querySelector('#playlist-close-button');
			const playlistShadeButton = document.querySelector('#playlist-shade-button');
			const playlistRemoveMenu = document.querySelector('#playlist-remove-menu');
			const eqClose = document.querySelector('#equalizer-close');
			const eqShade = document.querySelector('#equalizer-shade');
			const playListTitle = document.querySelector<HTMLDivElement>('.playlist-top-title.draggable');
			const resizeTarget = document.querySelector<HTMLDivElement>('#playlist-resize-target');
			const closePlaylistMenu = document.querySelector<HTMLDivElement>('.playlist-top-right.draggable');
			const playlistMisc = document.querySelector<HTMLDivElement>('#playlist-misc-menu');


			if (playlistWindow) {
				const newElement = document.createElement('div');
				newElement.classList.add('new-element');
				newElement.style.position = 'absolute';
				newElement.style.width = '100%';
				newElement.style.height = '38px';
				newElement.style.backgroundColor = 'transparent';
				newElement.style.zIndex = '9990';
				newElement.style.bottom = '0px';
				newElement.style.right = '0px';
				playlistWindow.appendChild(newElement);
			}

			if (playlistWindow) {
				const newElement = document.createElement('div');
				newElement.classList.add('new-element');
				newElement.style.position = 'absolute';
				newElement.style.width = '100%';
				newElement.style.height = '20px';
				newElement.style.backgroundColor = 'transparent';
				newElement.style.zIndex = '9990';
				newElement.style.top = '0px';
				newElement.style.right = '0px';
				playlistWindow.appendChild(newElement);
			}

			if (playlistWindow) {
				const newElement = document.createElement('div');
				newElement.classList.add('new-element');
				newElement.style.position = 'absolute';
				newElement.style.width = '8px';
				newElement.style.height = '100%';
				newElement.style.backgroundColor = 'transparent';
				newElement.style.zIndex = '9990';
				newElement.style.top = '0px';
				newElement.style.right = '0px';
				playlistWindow.appendChild(newElement);
			}

			if (playlistWindow) {
				const newElement = document.createElement('div');
				newElement.classList.add('new-element');
				newElement.style.position = 'absolute';
				newElement.style.width = '8px';
				newElement.style.height = '100%';
				newElement.style.backgroundColor = 'transparent';
				newElement.style.zIndex = '9990';
				newElement.style.top = '0px';
				newElement.style.right = '14px';
				playlistWindow.appendChild(newElement);
			}

			if (playlistWindow) {
				const newElement = document.createElement('div');
				newElement.classList.add('new-element');
				newElement.style.position = 'absolute';
				newElement.style.width = '14px';
				newElement.style.height = '100%';
				newElement.style.backgroundColor = 'transparent';
				newElement.style.zIndex = '9990';
				newElement.style.top = '0px';
				newElement.style.left = '0px';
				playlistWindow.appendChild(newElement);
			}

			if (playlistMisc) {
				playlistMisc.addEventListener('click', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
			}


			if (closePlaylistMenu) {
				const newElement = document.createElement('div');
				newElement.classList.add('new-element');
				newElement.style.position = 'absolute';
				newElement.style.width = '20px';
				newElement.style.height = '10px';
				newElement.style.backgroundColor = 'transparent';
				newElement.style.zIndex = '9990';
				newElement.style.top = '3px';
				newElement.style.right = '3px';
				closePlaylistMenu.appendChild(newElement);
			}
			
			if (eqBar) {
				const newElement = document.createElement('div');
				newElement.classList.add('new-element');
				newElement.style.position = 'absolute';
				newElement.style.width = '24px';
				newElement.style.height = '12px';
				newElement.style.backgroundColor = 'transparent';
				newElement.style.zIndex = '9990';
				newElement.style.top = '3px';
				newElement.style.right = '3px';
				eqBar.appendChild(newElement);
			}


			if (eqWindowSelected) {
				eqWindowSelected.addEventListener('click', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
			}


			if (playListTitle) {
				playListTitle.style.backgroundImage = 'url(/images/Winamp/headerPlaylist.png)';
			}




			if (eqClose) {
				eqClose.addEventListener('click', handleEqCloseClick);
				eqClose.addEventListener('dblclick', handleEqCloseClick);
				eqClose.addEventListener('touchstart', handleEqCloseClick);
				eqClose.addEventListener('touchend', handleEqCloseClick);
			}

			
			if (eqShade) {
				eqShade.addEventListener('click', handleEqCloseClick);
				eqShade.addEventListener('touchstart', handleEqCloseClick);
				eqShade.addEventListener('dblclick', handleEqCloseClick);
				eqShade.addEventListener('touchend', handleEqCloseClick);
			}



			if (titleBar) {
				titleBar.style.backgroundImage = 'url(/images/Winamp/headerMain.png)';
				titleBar.addEventListener('touchmove', (event) => {
					event.preventDefault(); 
					event.stopPropagation(); 
				}, { passive: false });
			}
		
			if (eqBar) {
				eqBar.addEventListener('touchmove', (event) => {
					event.preventDefault();
					event.stopPropagation();
				}, { passive: false });
				eqBar.style.backgroundImage = 'url(/images/Winamp/headerEq.png)';
			}
		
			if (playlistHeader) {
				playlistHeader.addEventListener('touchmove', (event) => {
					event.preventDefault();
					event.stopPropagation();
				}, { passive: false });
			}

			if (addUrl) addUrl.addEventListener('click', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});

			if (playlistRemoveMenu) playlistRemoveMenu.addEventListener('click', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});

			if (playlistCloseButton) playlistCloseButton.addEventListener('click', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});
			if (playlistShadeButton) playlistShadeButton.addEventListener('click', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});

			if (playlistButton) playlistButton.addEventListener('click', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});

			if (eqButton) eqButton.addEventListener('click', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});
			
			if (playlistHeader) {
				playlistHeader.addEventListener('mousedown', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
				playlistHeader.addEventListener('touchStart', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
				playlistHeader.addEventListener('touchMove', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
				playlistHeader.addEventListener('touchEnd', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
				playlistHeader.addEventListener('dblclick', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
			}
			
			if (about) {
				about.attributes.removeNamedItem('href');
			}
			
			if (playlistListMenu) {
				playlistListMenu.addEventListener('click', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});}
			
			if (option) option.addEventListener('click', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});
			
			if (eqWindow) {
				const equalizerWindow = eqWindow.parentElement;
				eqWindow.addEventListener('contextmenu', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
			}


			if (playlistWindow) playlistWindow.addEventListener('contextmenu', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});

			if (mainWindow) {
				mainWindow.addEventListener('contextmenu', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
			}

			if (eqBar) {
				eqBar.addEventListener('dblclick', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
				eqBar.addEventListener('mousedown', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
				eqBar.addEventListener('touchStart', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
				eqBar.addEventListener('touchMove', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
				eqBar.addEventListener('touchEnd', (event) => {
					event.preventDefault(); 
					event.stopPropagation();
				});
			}
			if (titleBar) titleBar.addEventListener('mousedown', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});
			if (titleBar) titleBar.addEventListener('dblclick', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});
			if (titleBar) titleBar.addEventListener('touchStart', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});
			if (titleBar) titleBar.addEventListener('touchMove', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});
			if (titleBar) titleBar.addEventListener('touchEnd', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});


			if (webamp) {
				webamp.style.position = 'fixed';
				webamp.style.width = '275px';
				webamp.style.height = '348px';
				webamp.style.transform = 'scale(1.35)';
				webamp.style.position = 'relative';



				const newElement = document.createElement('div');
				newElement.classList.add('new-element');
				newElement.style.position = 'absolute';
				newElement.style.width = '100%';
				newElement.style.height = '100%';
				newElement.style.backgroundImage = 'url(/images/Winamp/bgWinamp.png)';
				newElement.style.backgroundRepeat = 'no-repeat';
				newElement.style.backgroundSize = 'cover';
				newElement.style.backgroundPosition = 'center';
				newElement.style.display = 'flex';
				newElement.style.flexDirection = 'column';
				newElement.style.opacity = '1';
				newElement.style.zIndex = '9991';
				newElement.style.top = '50%';
				newElement.style.left = '50%';
				newElement.style.transform = 'translate(-50%, -50%)';

				const button = document.createElement('button');
				button.style.position = 'absolute';
				button.style.width = '12px';
				button.style.height = '12px';
				button.style.backgroundColor = 'transparent';
				button.style.zIndex = '9992';
				button.style.top = '3px';
				button.style.right = '3px';
				button.style.border = '0px';
				button.style.cursor = 'pointer';
				button.addEventListener('click', () => {
					newElement.style.display = 'none';
				});
				newElement.appendChild(button);






				const closeButton = document.createElement('button');
				closeButton.style.width = '55px';
				closeButton.style.height = '18px';
				closeButton.style.backgroundColor = '#373854';
				closeButton.style.color = '#84755D';
				closeButton.style.fontFamily = 'Arial, sans-serif'; 
				closeButton.style.fontSize = '12px'; 
				closeButton.style.textRendering = 'optimizeSpeed';
				closeButton.style.cursor = 'pointer';
				closeButton.style.position = 'absolute';
				closeButton.style.bottom = '30px';
				closeButton.style.left = '50%';
				closeButton.style.transform = 'translateX(-50%)';
				closeButton.style.backgroundImage = 'url(/images/Winamp/OK.png)';
				closeButton.style.backgroundRepeat = 'no-repeat';
				closeButton.style.backgroundSize = '20px 11px';
				closeButton.style.backgroundPosition = 'center';
				closeButton.addEventListener('click', () => {
					const wrapper = document.querySelector<HTMLDivElement>('#webamp');
					if (wrapper && wrapper.children[0]) {
						const winamp = wrapper.children[0] as HTMLDivElement;
						winamp.style.display = 'flex';
					}
					newElement.style.display = 'none';
				});
				// const buttonText = document.createTextNode('OK');
				// closeButton.appendChild(buttonText)
				newElement.appendChild(closeButton);


				const gif = document.createElement('img');
				gif.src = '/images/Winamp/gif2.gif';
				gif.style.width = '90px';
				gif.style.height = '140px';
				gif.style.position = 'absolute';
				gif.style.top = '30px';
				gif.style.left = '50%';
				gif.style.transform = 'translateX(-50%)';
				gif.style.filter = 'grayscale(100%)';
				newElement.appendChild(gif);







				const text = document.createElement('div');
				text.style.width = '200px';
				text.style.height = '100px';
				text.style.position = 'absolute';
				text.style.top = '180px';
				text.style.left = '50%';
				text.style.transform = 'translateX(-50%)';
				// text.style.backgroundColor = 'red';
				text.style.backgroundImage = 'url(/images/Winamp/text.png)';
				text.style.backgroundRepeat = 'no-repeat';
				text.style.backgroundSize = 'cover';
				text.style.backgroundPosition = 'center';
				newElement.appendChild(text);


				
				webamp.appendChild(newElement);

			
				const child = webamp.querySelector<HTMLElement>(':first-child');

				if (child) {
					child.style.width = '275px';
					child.style.height = '348px';
				}


				const childchild = child?.querySelector<HTMLElement>(':first-child');
				if (childchild) {
					childchild.style.width = '275px';
					childchild.style.height = '348px';
					childchild.style.display = 'flex';
					childchild.style.flexDirection = 'column';
				}
				const mainWindow = childchild?.querySelector<HTMLElement>(':first-child');
				const playlistWindow = mainWindow?.nextElementSibling as HTMLElement;
				const eqWindow = playlistWindow?.nextElementSibling as HTMLElement;

				if (mainWindow) {
					mainWindow.style.width = '275px';
					mainWindow.style.height = '116px';
					mainWindow.style.transform = 'translate(0, 0)';
					mainWindow.style.position = 'static';
					mainWindow.style.order = '1';
				}
				if (playlistWindow) {
					playlistWindow.style.width = '275px';
					playlistWindow.style.height = '116px';
					playlistWindow.style.transform = 'translate(0, 0)';
					playlistWindow.style.position = 'static';
					playlistWindow.style.order = '3';
				}
				if (eqWindow) {
					eqWindow.style.width = '275px';
					eqWindow.style.height = '116px';
					eqWindow.style.transform = 'translate(0, 0)';
					eqWindow.style.position = 'static';
					eqWindow.style.order = '2';
				}
			}

			if (closeButton) closeButton.addEventListener('click', (event) => {
				event.preventDefault(); 
				event.stopPropagation();
			});
		}
	}, [isRender]);

	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (modalRef && modalRef.current) {
			// const newElement = document.createElement('div');
			// newElement.classList.add('new-element');
			// newElement.style.position = 'absolute';
			// newElement.style.width = '372px';
			// newElement.style.height = '470px';
			// newElement.style.backgroundImage = 'url(/images/Winamp/bgWinamp.png)';
			// newElement.style.backgroundRepeat = 'no-repeat';
			// newElement.style.backgroundSize = 'cover';
			// newElement.style.backgroundPosition = 'center';
			// newElement.style.transform = 'scale(1)';
			
			// newElement.style.opacity = '1';
			// newElement.style.zIndex = '9991';
			// newElement.style.top = '50%';
			// newElement.style.left = '50%';
			// newElement.style.transform = 'translate(-50%, -50%)';

			// const button = document.createElement('button');
			// button.style.position = 'absolute';
			// button.style.width = '12px';
			// button.style.height = '12px';
			// button.style.backgroundColor = 'transparent';
			// button.style.zIndex = '9992';
			// button.style.top = '3px';
			// button.style.right = '3px';
			// button.style.border = '0px';
			// button.addEventListener('click', () => {
			// 	newElement.style.display = 'none';
			// });

			// newElement.appendChild(button);


			// modalRef.current.appendChild(newElement);
		}

	}, []);


	return (
		<Styled.WinampWrapper ref={modalRef}>
			<Styled.Winamp ref={webampRef}>

			</Styled.Winamp>
		</Styled.WinampWrapper>
	);
}

