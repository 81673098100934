import styled from "styled-components";
import { fonts } from "@config/GlobalFonts";
import { screens } from "@config/screens";

export const PresaveMorokomorBox = styled.div<{bgImage: string}>`

	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-image: url(${props => props.bgImage});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
	
	}
`;

export const PresaveMorokomor = styled.div`
	padding-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 375px;
`;

export const Cover = styled.img`
	width: 100%;
	height: 375px;
`;

export const TextBox = styled.div`
	width: 100%;
	min-height: 88px;
	display: flex;
	flex-direction: column;
	background-color: #292929;
	padding-top: 14px;
	padding-left: 16px;
`;

export const Artist = styled.p<{isArtist: boolean}>`
	font-size: 22px;
	max-width: fit-content;
	color: #ffffff;
	margin-bottom: 6px;
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.font500};
	position: relative;
	&::before {
		display: ${props => props.isArtist ? 'block' : 'none'};
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		bottom: 3px;
		right: -30px;
		background-image: url('/images/PresaveMorokomor/verified.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	
`;

export const Song = styled.p`
	font-size: 18px;
	color: #949494;
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.font500};
`;

export const StreamItems = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const StreamItem = styled.div`
	display: flex;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
	height: 78px;
	background-color: #000000;
	transition: background-color 0.3s;
	&:hover {
		background-color: #191919;
	}
`;

export const StreamItemIcon = styled.img`
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin-right: 24px;
	
`;

export const StreamItemName = styled.p`
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	font-size: 16px;
	color: #ffffff;
	margin-left: 12px;
	user-select: none;
`;

export const StreamItemLink = styled.a`
	margin-left: auto;
	border-radius: 12px;
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	font-size: 14px;
	color: #ffffff;
	background-color: #E73D43;
	text-decoration: none;
	padding: 10px 18px;
	border: none;
	cursor: pointer;
	outline: none;
	transition: background-color 0.3s;
	&:hover {
		background-color: #ED5D62;
	}
`;







// Form
export const FormMorokomorBox = styled.div<{bgImage: string}>`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-image: url(${props => props.bgImage});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
	
	}
`;

export const FormMorokomor = styled.form`
	padding-top: 50px;
	padding-bottom: 140px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 375px;
`;

export const Inputs = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const InputBox = styled.div`
	width: 100%;
	min-height: 100px;
	padding-top: 15px;
	padding-left: 25px;
	padding-right: 25px;
	display: flex;
	flex-direction: column;
	background-color: #191919;
	justify-content: center;
`;

export const Label = styled.label`
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	font-size: 16px;
	color: #ffffff;
	margin-bottom: 9px;
`;

export const Input = styled.input<{isError: boolean}>`
	width: 100%;
	height: 40px;
	border-radius: 12px;
	border: none;
	padding-left: 14px;
	padding-right: 14px;
	outline: none;
	background-color: ${({isError}) => isError ? '#70383D' : '#2E2020'};
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	font-size: 16px;
	color: #ffffff;
	
`;

export const Submit = styled.button`
	width: 100%;
	height: 40px;
	border-radius: 12px;
	border: none;
	background-color: #E73D43;
	color: #ffffff;
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	margin-top: 40px;
	margin-bottom: 40px;
`;



// Done
export const DoneBox = styled.div<{bgImage: string}>`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-image: url(${props => props.bgImage});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
	
	}
`;

export const Done = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 375px;
	align-self: center;
`;