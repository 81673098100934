export const content = {
	bgImage: '',
	cover: '/images/Monada/cover.webp',
	artist: 'МОРОК',
	song: 'Монада',
	streamings: [
		{
			logo: '/images/PresaveMorokomor/yandex.svg',
			name: 'Яндекс Музыка',
			button: {
				text: 'Слушать',
				link: 'https://music.yandex.ru/album/31581328'
			}
		},
		{
			logo: '/images/PresaveMorokomor/vk.svg',
			name: 'VK Музыка',
			button: {
				text: 'Слушать',
				link: 'https://boom.ru/redirect/album/26373074?mt_sub3=26373074&mt_sub1=%25D0%259C%25D0%25BE%25D0%25BD%25D0%25B0%25D0%25B4%25D0%25B0&mt_click_id=mt-uvjaj4-1718728284-1382535933&mt_sub2=%2527album%2527'
			}
		},
		{
			logo: '/images/PresaveMorokomor/spotify.svg',
			name: 'Spotify',
			button: {
				text: 'Слушать',
				link: 'https://open.spotify.com/track/5ZARtd2miKaKlBLjYSXU1J'
			}
		},
		{
			logo: '/images/PresaveMorokomor/apple.svg',
			name: 'Apple music',
			button: {
				text: 'Слушать',
				link: 'https://music.apple.com/ru/album/%D0%BC%D0%BE%D0%BD%D0%B0%D0%B4%D0%B0-single/1750543043'
			}
		},
	],
}
