import styled, {keyframes, css} from "styled-components";
import { fonts } from "@config/GlobalFonts";
import { screens } from "@config/screens";

export const PresaveMorokomorBox = styled.div<{bgImage: string}>`
	padding-top: 32px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-image: url(${props => props.bgImage});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
	
	}
`;

export const PresaveMorokomor = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 375px;
`;

export const CoverFirst = styled.img`
	top: 0;
	left: 0;
	position: absolute;
	width: 375px;
	height: 680px;
	z-index: -1;
`;

export const CoverSecond = styled.img`
	width: 375px;

`;
	
export const Name = styled.p`
	font-size: 34px;
	margin-top: 75px;
    margin-left: -45px;
	font-family: ${fonts.palatino.family};
	font-weight: ${fonts.palatino.weights.font700};
	text-transform: uppercase;
	color: #ffffff;
	display: inline-block; /* или используйте inline для текста внутри абзаца */
  	transform: scaleX(1.3); /* Увеличивает горизонтальный масштаб на 130% */
  	transform-origin: left;
`;
	
export const Release = styled.p`
	font-size: 34px;
    margin-left: -72px;
	margin-top: 4px;
	margin-bottom: 20px;
	font-family: ${fonts.palatino.family};
	font-weight: ${fonts.palatino.weights.font700};
	text-transform: uppercase;
	color: #ffffff;
	display: inline-block; /* или используйте inline для текста внутри абзаца */
  	transform: scaleX(1.3); /* Увеличивает горизонтальный масштаб на 130% */
  	transform-origin: left;
`;

export const Intro = styled.p`
	font-size: 14px;
	font-family: ${fonts.asap.family};
	font-weight: ${fonts.asap.weights.font700};
	color: #ffffff;
	text-align: center;
	width: 285px;
	margin: 0 auto;
	margin-bottom: 170px;
`;


export const VkBox = styled.div`
	display: flex;
	align-items: center;
	margin-left: 150px;
	margin-bottom: 10px;
`;

export const VkIcon = styled.img`
	border-radius: 8px;
	width: 34px;
	height: 34px;
	margin-right: 18px;
`;

export const VkText = styled.p`
	font-size: 19px;
	font-family: ${fonts.asap.family};
	font-weight: ${fonts.asap.weights.font700};
	color: #ffffff;
`;

export const VkLink = styled.a`
	width: 134px;
	height: 40px;
	border-radius: 14px;
	font-size: 15px;
	font-family: ${fonts.asap.family};
	font-weight: ${fonts.asap.weights.font700};
	color: #000000;
	text-transform: uppercase;
	background-color: #ffffff;
	text-align: center;
    padding-top: 11px;
    user-select: none;
    cursor: pointer;
	margin-left: 161px;
	margin-bottom: 16px;
	text-decoration: none;
	transition: 0.2s;
	&:hover {
		transform: translateY(-2px);
	}
`;



export const YaBox = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	margin-left: -86px;
`;

export const YaIcon = styled.img`
	border-radius: 8px;
	width: 34px;
	height: 34px;
	margin-right: 18px;
`;

export const YaText = styled.p`
	font-size: 19px;
	font-family: ${fonts.asap.family};
	font-weight: ${fonts.asap.weights.font700};
	color: #ffffff;
`;

export const YaLink = styled.a`
	width: 134px;
	height: 40px;
	border-radius: 14px;
	font-size: 15px;
	font-family: ${fonts.asap.family};
	font-weight: ${fonts.asap.weights.font700};
	color: #000000;
	text-transform: uppercase;
	background-color: #ffffff;
	text-align: center;
    padding-top: 11px;
    user-select: none;
    cursor: pointer;
	margin-left: -86px;
	margin-bottom: 8px;
	text-decoration: none;
	transition: 0.2s;
	&:hover {
		transform: translateY(-2px);
	}
`;

export const Outro = styled.p`
	font-size: 12px;
	font-family: ${fonts.asap.family};
	font-weight: ${fonts.asap.weights.font700};
	color: #ffffff;
	text-align: center;
	width: 258px;
	margin: 0 auto;
	margin-top: 10px;
`;




export const TextBox = styled.div`
	width: 100%;
	min-height: 88px;
	display: flex;
	flex-direction: column;
	background-color: #292929;
	padding-top: 14px;
	padding-left: 16px;
`;

export const Artist = styled.p<{isArtist: boolean}>`
	font-size: 22px;
	max-width: fit-content;
	color: #ffffff;
	margin-bottom: 6px;
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.font500};
	position: relative;
	&::before {
		display: ${props => props.isArtist ? 'block' : 'none'};
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		bottom: 3px;
		right: -30px;
		background-image: url('/images/PresaveMorokomor/verified.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	
`;

export const Song = styled.p`
	font-size: 18px;
	color: #949494;
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.font500};
`;

export const StreamItems = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const StreamItem = styled.div`
	display: flex;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
	height: 78px;
	background-color: #000000;
	transition: background-color 0.3s;
	&:hover {
		background-color: #191919;
	}
`;

export const StreamItemIcon = styled.img`
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin-right: 24px;
	
`;

export const StreamItemName = styled.p`
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	font-size: 16px;
	color: #ffffff;
	margin-left: 12px;
	user-select: none;
`;

export const StreamItemLink = styled.a`
	margin-left: auto;
	border-radius: 12px;
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	font-size: 14px;
	color: #ffffff;
	background-color: #E73D43;
	text-decoration: none;
	padding: 10px 18px;
	border: none;
	cursor: pointer;
	outline: none;
	transition: background-color 0.3s;
	&:hover {
		background-color: #ED5D62;
	}
`;







// Form
export const FormMorokomorBox = styled.div<{bgImage: string}>`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-image: url(${props => props.bgImage});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
	
	}
`;

export const FormMorokomor = styled.form`
	padding-top: 50px;
	padding-bottom: 140px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 375px;
`;

export const Inputs = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const InputBox = styled.div`
	width: 100%;
	min-height: 100px;
	padding-top: 15px;
	padding-left: 25px;
	padding-right: 25px;
	display: flex;
	flex-direction: column;
	background-color: #191919;
	justify-content: center;
`;

export const Label = styled.label`
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	font-size: 16px;
	color: #ffffff;
	margin-bottom: 9px;
`;

export const Input = styled.input<{isError: boolean}>`
	width: 100%;
	height: 40px;
	border-radius: 12px;
	border: none;
	padding-left: 14px;
	padding-right: 14px;
	outline: none;
	background-color: ${({isError}) => isError ? '#3A3A3A' : '#595959'};
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	font-size: 16px;
	color: #ffffff;
	
`;

export const Submit = styled.button`
	width: 100%;
	height: 40px;
	border-radius: 12px;
	border: none;
	background-color: #000000;
	color: #ffffff;
	font-family: ${fonts.openSans.family};
	font-weight: ${fonts.openSans.weights.regular};
	margin-top: 40px;
	margin-bottom: 40px;
`;



// Done
export const DoneBox = styled.div<{bgImage: string}>`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-image: url(${props => props.bgImage});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
	
	}
`;

export const Done = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 375px;
	align-self: center;
`;






