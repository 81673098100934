export const content = {
	bgImage: '',
	coverFirst: '/images/PresaveMorokomor/morokomorBg.webp',
	coverSecond: '/images/PresaveMorokomor/cover.webp',
	artist: 'МОРОК',
	song: 'Морокомор',
	streamings: [
		{
			logo: '/images/PresaveMorokomor/vk.svg',
			presaveLogo: '/images/PresaveMorokomor/vkNew.svg',
			name: 'VK Музыка',
			button: {
				text: 'Пресейв',
				link: 'https://band.link/save-presave?type=vkmusic&bandlink_id=undefined&bandlink_hash=WuV6B&upc=3617386454937&redirectUrl=https://morok.co/'
				// link: 'https://band.link/save-presave?type=vkmusic&bandlink_id=966204&upc=3617386454937&redirectUrl=https://morok.co/'
				// link: 'https://band.link/save-presave?type=vkmusic&bandlink_id=966204&upc=3617386454937&redirectUrl=http://localhost:3000'
			}
		},
		{
			logo: '/images/PresaveMorokomor/yandex.svg',
			presaveLogo: '/images/PresaveMorokomor/yandexNew.svg',
			name: 'Яндекс Музыка',
			button: {
				text: 'Пресейв',
				link: 'https://band.link/save-presave?type=yandex&bandlink_id=undefined&bandlink_hash=WuV6B&upc=3617386454937&redirectUrl=https://morok.co/'
				// link: 'https://band.link/save-presave?type=yandex&bandlink_id=966204&upc=3617386454937&redirectUrl=https://morok.co/'
				// link: 'https://band.link/save-presave?type=yandex&bandlink_id=966204&upc=3617386454937&redirectUrl=http://localhost:3000'
			}
		},
	],
	form: {
		thanks: {
			title: 'Спасибо за пресейв',
			subtitle: 'Еще пару моментов'
		},
		inputs: [
			{
				label: 'Имя',
				id: 'name',
				type: 'text',
				autoComplete: 'false'	
			},
			{
				label: 'Telegram',
				id: 'telegram',
				type: 'text',
				autoComplete: 'false'	
			},
			{
				label: 'Почта*',
				id: 'email',
				type: 'email',
				autoComplete: 'false'	
			},
		],
		submit: 'Отправить'
	},
	done: {
		title: 'Теперь все готово!',
		subtitle: 'Желаю удачи'
	},
	dateOptions: {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false
	} as Intl.DateTimeFormatOptions
}
