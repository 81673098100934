export const initialTracks = [
	{
	  metaData: {
		title: "81",
		artist: "МÓРОК",
		album: "МОРОКОМОР",
		
	  },
	  url: "tracks/МÓРОК - 81.wav",
	},
	{
	  metaData: {
		title: "ЗАПЛУТАЛ",
		artist: "МÓРОК",
		album: "МОРОКОМОР",
		
	  },
	  url: "tracks/МÓРОК - ЗАПЛУТАЛ.wav",
	},
	{
	  metaData: {
		title: "КАЮК",
		artist: "МÓРОК",
		album: "МОРОКОМОР",
		
	  },
	  url: "tracks/МÓРОК - КАЮК.wav",
	},
	{
	  metaData: {
		title: "МАККУОРИ",
		artist: "МÓРОК",
		album: "МОРОКОМОР",
		
	  },
	  url: "tracks/МÓРОК - МАККУОРИ.wav",
	},
	{
	  metaData: {
		title: "САМАРИТЯНИН",
		artist: "МÓРОК",
		album: "МОРОКОМОР",
		
	  },
	  url: "tracks/МÓРОК - САМАРИТЯНИН.wav",
	},
	{
	  metaData: {
		title: "ВЕСНЫ ФАВИКОН",
		artist: "МÓРОК",
		album: "МОРОКОМОР",
		
	  },
	  url: "tracks/МÓРОК - ВЕСНЫ ФАВИКОН.wav",
	},
	{
	  metaData: {
		title: "КАРМАГЕДДОН",
		artist: "МÓРОК",
		album: "МОРОКОМОР",
		
	  },
	  url: "tracks/МÓРОК - КАРМАГЕДДОН.wav",
	},
	{
	  metaData: {
		title: "МОР",
		artist: "МÓРОК",
		album: "МОРОКОМОР",
		
	  },
	  url: "tracks/МÓРОК - МОР.wav",
	},
	{
	  metaData: {
		title: "СКВОЗЬ СНЕГ (FEAT. ВЕРТУШКА ГАЗМАНОВА)",
		artist: "МÓРОК",
		album: "МОРОКОМОР",
		
	  },
	  url: "tracks/МÓРОК - Сквозь снег (feat. вертушка газманова).wav",
	},
]