import React, { FC, FormEvent, useEffect, useState } from 'react';
import axios from 'axios';
import { IPresaveMorokomor } from './types';
import { content } from './scheme';
import { useLocation } from 'react-router-dom';

import * as Styled from './styled';




export const PresaveMorokomor: FC<IPresaveMorokomor> = ({}) => {
	const location = useLocation();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [tg, setTg] = useState('');
	const [typeOfPresave, setTypeOfPresave] = useState('');
	const [isError, setIsError] = useState(false);
	const [isDone, setIsDone] = useState(false);





	useEffect(() => {
		if (location.search !== '') setTypeOfPresave(location.search.includes('vkmusic') ? 'vkmusic' : 'yandex');
	}, []);
	
	const handleName = (value: string) => {
		setName(value);
	}
	const handleEmail = (value: string) => {
		setEmail(value);
	}
	const handleTg = (value: string) => {
		setTg(value);
	}

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		if (email === '') {
			setIsError(true);
		} else {
			const data = {
				Name: name,
				Telegram: tg,
				Streaming: typeOfPresave,
				Email: email,
				Date: new Date().toLocaleString('en-US', content.dateOptions)
			}
			const response = await axios.post('https://sheet.best/api/sheets/c8524259-419d-40ce-9d1a-0185d1c7e5b3', data)
			console.log(response);
			setIsDone(true);
		}
	}
	return (
		<>
			{!location.search.includes('vkmusic') && !location.search.includes('yandex')
				? (
					<Styled.PresaveMorokomorBox bgImage={content.bgImage}>
						<Styled.PresaveMorokomor>
							<Styled.CoverFirst src={content.coverFirst} />
							<Styled.Name>
								Морок
							</Styled.Name>
							<Styled.Release>
								Морокомор
							</Styled.Release>
							<Styled.Intro>
								Дебютный альбом парижско-самарского исполнителя, в котором он переосмысляет русский фольклор — и соединяет его с космическими битами
							</Styled.Intro>

							<Styled.VkBox>
								<Styled.VkIcon src={content.streamings[0].presaveLogo} />
								<Styled.VkText>VK МУЗЫКА</Styled.VkText>
							</Styled.VkBox>
							<Styled.VkLink onClick={() => window._tmr.push({ type: 'reachGoal', id: 3486951, goal: 'stream_button_click'})} href={content.streamings[0].button.link}>{content.streamings[0].button.text}</Styled.VkLink>
						
							<Styled.YaBox>
								<Styled.YaIcon src={content.streamings[1].presaveLogo} />
								<Styled.YaText>ЯНДЕКС МУЗЫКА</Styled.YaText>
							</Styled.YaBox>
							<Styled.YaLink onClick={() => window._tmr.push({ type: 'reachGoal', id: 3486951, goal: 'stream_button_click'})} href={content.streamings[1].button.link}>{content.streamings[1].button.text}</Styled.YaLink>
							
							<Styled.Outro>
								Сделайте пресейв альбома, чтобы получить возможность выиграть письмо от МОРОКа и войти в историю
							</Styled.Outro>
						</Styled.PresaveMorokomor>
					</Styled.PresaveMorokomorBox>
				)
				: (
					<>
						{!isDone 
							? (
								<Styled.FormMorokomorBox bgImage={content.bgImage}>
									<Styled.FormMorokomor>
										<Styled.CoverSecond src={content.coverSecond} />
											<Styled.TextBox>
												<Styled.Artist isArtist={false}>{content.form.thanks.title}</Styled.Artist>
												<Styled.Song>{content.form.thanks.subtitle}</Styled.Song>
											</Styled.TextBox>
											<Styled.Inputs>
												{content.form.inputs.map((input, index) => (
													<Styled.InputBox key={`Input_${index}`}>
														<Styled.Label htmlFor={input.id}>{input.label}</Styled.Label>
														<Styled.Input placeholder={input.id === 'email' && isError ? 'Заполните данные' : ''} isError={input.id === 'email' ? isError : false} onChange={(e) => input.id === 'name' ? handleName(e.target.value) : input.id === 'email' ? handleEmail(e.target.value) : handleTg(e.target.value)} value={input.id === 'name' ? name : input.id === 'email' ? email : tg} id={input.id} type={input.type} autoComplete={input.autoComplete} />
													</Styled.InputBox>
												))}
												<Styled.InputBox>
													<Styled.Submit onClick={handleSubmit}>{content.form.submit}</Styled.Submit>
												</Styled.InputBox>
											</Styled.Inputs>
									</Styled.FormMorokomor>
								</Styled.FormMorokomorBox>
							)
							: (
								<Styled.DoneBox bgImage={content.bgImage}>
									<Styled.Done>
										<Styled.CoverSecond src={content.coverSecond} />
										<Styled.TextBox>
												<Styled.Artist isArtist={false}>{content.done.title}</Styled.Artist>
												<Styled.Song>{content.done.subtitle}</Styled.Song>
											</Styled.TextBox>
									</Styled.Done>
								</Styled.DoneBox>
							)
						}
					</>
				)
			}
		</>
	);
}